import { graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import React from "react"
import Layout from "../components/layout"
import HeroHeadline from "../components/heroheadline"
import * as hero from '../styles/hero.module.css'
import * as styles from '../styles/esports.module.css'
import Sectionheader from "../components/sectionheader"

export default function Esports() {
    const data = useStaticQuery(graphql`
        query EsportsHero {
            file(relativePath: {eq: "heroes/AdobeStock_278715246.jpeg"}) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return(
        <Layout>
            <div className={hero.smarthero}>
                <Img fluid={data.file.childImageSharp.fluid} className={hero.smartheroimage}/>
                <HeroHeadline h1="Academic Esports" sub="level up" />
            </div>
            <div className={styles.who}>
                <div className="container">
                    <div className="row align-items-start">
                        <Sectionheader name="About Academic Esports" />
                        <div className="col-11 col-sm-8">
                            <p><strong>The Mission of Academic Esports:</strong> TSU Academic eSports Initiatives and eSports Teams are established and designed to promote pathways into STEAM (Science, Technology, Engineering, Arts, and Math), as well as offer eSports Team Opportunities to create, code, and compete from PreK, collegiate teams, to lifelong learners. eSports encompasses all disciplines from education, business, computer science, engineering, psychology, communication, health sciences, agriculture and natural life sciences, liberal arts, and the fine arts of music, arts, and theater.</p>
                            <div className={styles.learnMore}><a role="button" className="btn btn-outline-primary" href="https://ai-tnstatesmartcenter.org ">Learn More</a></div>
                            <div className="videocontainer">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/Gq1dxJYMmtI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <p>&nbsp;</p>
                            <p><strong>Fall 2021 eSports Courses:</strong> (online virtual play and learning) No prerequisite</p>
                            <ul>
                                <li>Undergraduate Course: TELC 4005- Teaching and Learning with Technology<br />
                                eSports (UG) <a href="https://bannerssb.tnstate.edu/pls/PROD/bwckschd.p_disp_detail_sched?term_in=202180&crn_in=82004" target="_blank" rel="noreferrer">Tch and Learning with Tech - ESPORTS 82004 - TELC 4005 - 98A</a> (ESPORTS)
                                </li>
                                <li>Graduate (Masters): TELC 5005 – Teaching and Learning with Technology<br />
                                eSports <a href="https://bannerssb.tnstate.edu/pls/PROD/bwckschd.p_disp_detail_sched?term_in=202180&crn_in=81995" target="_blank" rel="noreferrer">Tch and Learning with Tech - ESPORTS 81995 - TELC 5005 - 98B</a> (ESPORTS)
                                </li>
                                <li>Graduate (Doctoral): EDAD 6100. Computer Application (Emphasis eSports)<br />
                                <a href="https://bannerssb.tnstate.edu/pls/PROD/bwckschd.p_disp_detail_sched?term_in=202180&crn_in=81984" target="_blank" rel="noreferrer">Computer Application ESPORTS - 81984 - EDAD 6100 - 98</a> (ESPORTS)
                                </li>
                            </ul>
                            <hr />
                            <p><strong>TSU eSports Arena:</strong> Main Campus - Humphries Building (opening Fall 2021)</p>
                            <div className="videocontainer">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/i9fKWxqqLxA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="col-11 col-sm-3">
                            <div className={styles.teams}>
                                <h3>TSU eSports Teams</h3>
                                <h4>Current Teams</h4>
                                <ul>
                                    <li>Call of Duty</li>
                                    <li>Fortnite</li>
                                    <li>NBA 2K</li>
                                    <li>Madden</li>
                                    <li>FIFA</li>
                                </ul>
                                <h4>Upcoming Teams</h4>
                                <ul>
                                    <li>NHL</li>
                                    <li>Pokemon</li>
                                    <li>Rocket League</li>
                                    <li>Track and Field</li>
                                    <li>Valorant</li>
                                    <li>PGA Golf</li>
                                    <li>iRacing</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </Layout>
    )
}